import { useMemo } from "react";
import { useRoutes } from "react-router-dom";

/**
 * Resolve routes by authentication 
 * @param {Function} useAuth0 useAuth0 hook from package "@divide/retailsuite-react-components"
 * @param {*} privateRoutes private routes list object as specified by react-router-dom
 * @param {*} publicRoutes public routes list object as specified by react-router-dom
 * @returns 
 */
export function useResolveRoutesByAuthentication (useAuth0, privateRoutes, publicRoutes) {
    const { isAuthenticated, loading } = useAuth0();

    const routes = useMemo(() => {
        if (!loading) {
            if (isAuthenticated) {
                return privateRoutes;
            } else {
                return publicRoutes;
            }
        } else {
            return [{ path: "*", element: null }];
        }
    }, [isAuthenticated, loading, privateRoutes, publicRoutes]);

    return {
        routes,
        element: useRoutes(routes),
    }
}