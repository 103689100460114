import { sharedStorageKeys } from "@divide/retailsuite-react-framework/constants";

const storageKeys = {
    date: "oc_date",
    imageBaseUrl: "oc_image-base-url",
    setOrder: "oc_set-order",
    productBasedCurrentSort: "oc_product-based-current-sort",
    activeRequest: "oc_active-request",
    cdnDomain: "oc_cdn-domain",
    addToHomeScreenMessageIsClosed: "oc_add-to-home-screen-message-is-closed",
    hasUsedHomeScreenAppMessagesIsClosed: "oc_has-used-home-screen-message-is-closed",
    hasUsedHomeScreenApp: "oc_has-used-home-screen-app",
    updateAvailable: "oc_update_available",
    storageCrateBarcode: "oc_crate-barcode",
    ...sharedStorageKeys
};

export default storageKeys;