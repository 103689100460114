const deDe = {
    global: {
        logout: "Abmelden",
        reason: "Grund",
        identifier: "Kennung",
        confirm: "Bestätigung",
        cancel: "Absagen",
        brand: "Marke",
        color: "Farbe",
        vendorArticleNumberShort: "Artikelnr. Lief.",
        vendorColorShort: "Farbe Lief.",
        price: "Preis",
        size: "Größe",
        xTypeOrders: (type) => `${type} Bestellungen`,
        amountOrdersFound: (amount, type) => `${amount} ${type} Bestellung(en) gefunden`,
        amountOrdersFoundLast7Days: (amount, type) => `${amount} ${type} Bestellung(en) in den letzten 7 Tagen gefunden`,
        created: "Neu",
        open: "Open",
        pending: "In Behandlung",
        picked: "Gepflückt",
        completed: "Fertig",
        rejected: "Abgelehnt",
        cancelled: "Abgesagt",
        undeliverable: "Mangel",
        delivery: "Versenden",
        sender: "Absender",
        homeDelivery: "Hauslieferung",
        pickupCode: (code) => `Abholen (${code})`,
        nameClient: "Namensempfänger",
        date: "Datum",
        xAmountProducts: (amount) => `${amount} Produkt(e)`,
        view: "Sehen",
        processOrder: "Prozess Bestellung",
        pickOrder: "Auftrag zusammenstellen",
        printOrder: "Bestellung drucken",
        acceptAsUndeliverable: "Akzeptieren als Mangel",
        recirculate: "Rezirkulieren",
        productsInXOrder: (orderNumber) => `Produkt(e) in Bestellung&nbsp;<strong>${orderNumber}</strong>`,
        paidPrice: "Bezahlter Preis",
        articleNr: "Artikelnummer",
        barcode: "Barcode",
        recirculateRequest: (identifier) => `Anfrage ${identifier} rezirkulieren`,
        withoutBranch: "Ohne Store",
        withBranch: "Mit Store",
        selectReason: "Wähle einen Grund",
        selectBranch: "Wähle einen Store",
        openProducts: "Unverarbeitete(s) Produkt(e)",
        pickedProducts: "Ausgewählte(s) Produkt(e)",
        completedProducts: "Abgeschlossen Produkt(e)",
        undeliverableProducts: "Mangel produkt(e)",
        amountTypeProductsFound: (amount, type) => `${amount} ${type} gefunden`,
        acceptMancoProductConfirm: (barcode, identifier) =>
            `Möchten Sie das Produkt <b>${barcode}</b> in der Anfrage <b>${identifier}</b> wirklich als Mangel akzeptieren?`,
        accept: "Annehmen",
        reject: "Ablehnen",
        orderNr: "Bestell num.",
        recirculateProduct: (barcode) => `Produkt ${barcode} rezirkulieren`,
        rejectProduct: "Produkt ablehnen",
        reasonForRejection: (barcode) => `Aus welchem Grund möchten Sie dieses Produkt ablehnen (${barcode})?`,
        reasonForRecirculation: "Aus welchem Grund möchten Sie diese Produkte ablehnen?",
        articleNumber: "Artikelnummer",
        name: "Name",
        sortBy: "Sortierreihenfolge",
        openPlural: "Unverarbeitete",
        pickedPlural: "Gepflückt",
        completedPlural: "Abgeschlossen",
        unkownPlural: "Unbekannt",
        acceptUndeliverableRequest: (identifier) => `Möchten Sie die ${identifier}-Anforderung wirklich als Mangel akzeptieren?`,
        searchByOrderNr: "Suche nach bestellnummer",
        search: "Suchen...",
        unknown: "Unbekannt",
        request: (identifier) => `Anfrage "${identifier}"`,
        orderNumber: "Bestellnummer",
        creationDate: "Erstellungsdatum",
        searchByBarcode: "Suche nach Barcode...",
        acceptAll: "Alles akzeptiere ",
        acceptRemaining: "Verbleibende akzeptieren",
        rejectAll: "Alles verweigern",
        rejectRemaining: "Verbleiben verweigern",
        undoAll: "Alles ungeschehen machen",
        confirmSelection: "Auswahl bestätigen",
        selected: "Ausgewählt",
        noSelectedArticles: "Keine ausgewählten Artikel",
        selectedArticle: (description, size) => `${description}, Größe ${size}`,
        selectAReason: "Wähle einen grund",
        packstation: "Packstation",
        selectAPackstation: "Packstation auswählen",
        submitRequest: "Anfrage",
        bundledRequestNotice:
            "<strong>Hinweis:</strong> Artikel wurden sowohl angenommen als auch abgelehnt. Eine Anfrage kann nur gebündelt eingereicht werden.<br/><br/>Wenn ein oder mehrere Artikel abgelehnt werden, müssen Sie die Anfrage vollständig ablehnen.",
        bundledRequestNoticePicked:
            "<strong>Hinweis:</strong> Artikel wurden sowohl angenommen als auch abgelehnt. Eine Bestellung kann nur gebündelt eingereicht werden, da Sie die Bestellung bearbeiten.<br/><br/>Wenn ein oder mehrere Artikel abgelehnt wurden, müssen Sie die Bestellung vollständig ablehnen, die gesamte Bestellung muss dann erneut kommissioniert werden.",
        xTypeProducts: (type) => `${type} producten`,
        xAmountOfxTypeProductsFound: (amount, type) => `${amount} ${type} ${amount === 1 ? "product" : "producten"} gevonden.`,
        structureCode: "Strukturcode",
        reset: "Zurücksetzen",
        itemsFoundXFromX: (amount, total) => `${amount} von ${total} Artikeln gefunden`,
        pick: "Wählen",
        noCrateSelected: "Derzeit ist keine Kiste ausgewählt",
        noCrateSelectedDescription: "Scannen Sie den Barcode einer Kiste, bevor Sie mit der Kommissionierung der Produkte beginnen.",
        scanCrate: "Kiste scannen",
        scanCrateDescription: "Scannen Sie nun den Barcode einer Kiste",
        selectedCrateBarcode: (barcode) => `Kiste mit Barcode <b>'${barcode}'</b> ist ausgewählt`,
        acceptSelectedCrateBarcode: "Ich bestätige, dass der obige Barcode korrekt ist.",
        startPickingProducts: "Sie können nun mit der Auswahl der Produkte beginnen.",
        viewCrate: "Kiste anzeigen",
        completeCrate: "Komplette Kiste",
        confirmCompletionCrate: "Möchten Sie diese Kiste wirklich vervollständigen?",
        crateDialogTitle: (barcode) => `Kiste ${barcode}`,
        crateSuccessfullyCompleted: "Kiste erfolgreich fertiggestellt.",
        noProductsInCrate: "In dieser Kiste befinden sich keine Produkte.",
        oneProductInCrate: "In dieser Kiste befindet sich ein Produkt.",
        xProductsInCrate: (amount) => `In dieser Kiste befinden sich ${amount} Produkte.`,
        continue: "Fortsetzen",
        close: "Schließen",
        delete: "Löschen",
        resetFilters: "Alles zurücksetzen",
        recirculateAcceptedOrder: "Möchten Sie die ausgewählten Produkte wirklich erneut in Umlauf bringen?",
        selectProductsToRecirculate: "Produkte zur Wiederverwendung auswählen",
    },
    filters: {
        shippingAgents: "Absender",
        productCount: "Anzahl der Produkte",
        singleProduct: "Einzelnes Produkt",
        multipleProducts: "Mehrere Produkte"
    },
    navigation: {
        open: "Offen",
        picked: "Ausgewählt",
        completed: "Vollendet",
        undeliverable: "Unzustellbare"
    },
    messages: {
        error: {
            somethingWentWrong: "Etwas ist schief gelaufen.",
            somethingWentWrongRecirculation: "Etwas ist schief gelaufen, daher konnte das Produkt nicht rezirkuliert werden.",
            somethingWentWrongAcceptMancoProduct: "Etwas ist schief gelaufen, daher konnte das defekte Produkt nicht angenommen werden.",
            somethingWentWrongAcceptProduct: "Etwas ist schief gelaufen, daher konnte das Produkt nicht angenommen werden.",
            somethingWentWrongRejectProduct: "Etwas ist schief gelaufen, daher konnte das Produkt nicht abgelehnt werden.",
            somethingWentWrongAcceptRequest: "Etwas ist schief gelaufen, daher konnte die Anfrage nicht angenommen werden.",
            articleByBarcodeNotFound: (barcode) => `Artikel mit Barcode "${barcode}" konnte nicht gefunden werden.`
        },
        warning: {
            noAccessToUndeliverables: "Mangelseite im Order Circulatie",
            barcodeNotFound: (barcode) => `Barcode "${barcode}" nicht gefunden.`,
            isNotCirculationBranch: "Bitte beachten Sie: Dieser Standort nimmt nicht am Bestellumlauf teil. Wenn Sie mit dieser Anfrage fortfahren, bearbeiten Sie die Anfrage bitte, indem Sie den Standort in dieser App auswählen."
        },
        success: {
            undeliverableRecirculated: "Die fehlende Anfrage wurde erfolgreich rezirkuliert!",
            undeliverableProductAccepted: "Das fehlende Produkt wurde erfolgreich akzeptiert.",
            productWasAccepted: (name) => `<strong>${name}</strong> ist erfolgreich akzeptiert.`,
            productWasAcceptedOnPage: (name, page) => `<strong>${name}</strong> wurde erfolgreich auf Seite <strong>${page}<strong> akzeptiert.`,
            productWasRejected: (name, reasonDescription) => `<strong>${name}</strong> ist erfolgreich abgelehnt (${reasonDescription}).`,
            undeliverableRequestAccepted: "Die Mangel-Anfrage wurde erfolgreich akzeptiert.",
            articleWithBarcodeAccepted: (barcode) => `1 Artikel mit Barcode "${barcode}" wurde akzeptiert.`,
            requestSubmitted: "Die Anfrage wurde erfolgreich übermittelt!",
            productWasRejectedPicked: (name) => `<strong>${name}</strong> wurde erfolgreich in den geöffneten Produkten wiederhergestellt.`,
            productWasPicked: (name) => `<strong>${name}</strong> wurde erfolgreich ausgewählt`,
            productWasPickedOnPage: (name, page) => `<strong>${name}</strong> wurde erfolgreich auf Seite <strong>${page}<strong> ausgewählt.`,
            productWasPlacedInCrate: (name) => `<strong>${name}</strong> wurde erfolgreich in die Kiste gelegt.`,
            productWasPlacedInCrateOnPage: (name, page) => `<strong>${name}</strong> wurde erfolgreich in die Kiste gelegt auf Seite <strong>${page}<strong> ausgewählt.`,
            recirculateAcceptedOrder: "Die ausgewählten Produkte werden erfolgreich wieder in den Umlauf gebracht.",
        },
        info: {
            noBranchWithPermission:
                "Sie sind nicht mit einer Filiale verbunden,<br/>aber Sie haben das Recht, eine Filiale zu imitieren.<br/>Um fortzufahren, wählen Sie oben rechts im Benutzermen eine Filiale aus<br/>oder melden Sie sich mit einem Konto an die mit einer Verzweigung verknöpft ist.",
            noBranchWithoutPermission:
                "Sie sind nicht mit einer Filiale verbunden.<br />Sie können keine Anfragen annehmen.<br /><br />Um Zugriff zu erhalten, müssen Sie sich mit einem<br />Konto anmelden die mit einer Verzweigung verknöpft ist.",
            requestWillBePrinted: "Die Anfrage wird gedruckt.",
            packstationIsSelected: (packstationCode) => `<strong>${packstationCode}</strong><br/>ist ausgewählt.`,
            productWillGetOpenStatus: `<strong>Hinweis:</strong> Der Artikel erhält den Status "offen" und muss im Reiter "Offen" erneut ausgewählt werden.`,
            acceptedProductsWillGetPickedStatus: `<strong>Hinweis:</strong> Die akzeptierten Artikel in dieser Anfrage erhalten den Status "gepflückt". Bearbeiten Sie dies im Reiter "Gepickt" weiter.`,
            requestWillGetOpenStatus: `<strong>Hinweis:</strong> Die Anfrage erhält den Status "offen" und muss im Reiter "Offen" erneut ausgewählt werden.`
        },
        validation: {
            selectReason: "Sie müssen einen Grund auswählen.",
            selectBranch: "Sie müssen einen Store auswählen."
        },
        loading: {
            default: "Data wird geladen...",
            oneMomentPlease: "Einen Moment bitte...",
            xTypeOrdersBeingLoaded: (type) => `${type} Bestellung wird geladen...`,
            xTypeProductsBeingLoaded: (type) => `${type} producten worden geladen...`,
            requestIsSubmitting: "Anfrage wurde eingereicht..."
        }
    }
};

export default deDe;
