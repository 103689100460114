const enGb = {
    global: {
        logout: "Log out",
        reason: "Reason",
        identifier: "Identifier",
        confirm: "Confirmation",
        cancel: "Cancel",
        brand: "Brand",
        color: "Color",
        vendorArticleNumberShort: "Art no. vendor",
        vendorColorShort: "Vendor color.",
        price: "Price",
        size: "Size",
        xTypeOrders: (type) => `${type} orders`,
        amountOrdersFound: (amount, type) => `${amount} ${type} order(s) found`,
        amountOrdersFoundLast7Days: (amount, type) => `${amount} ${type} order(s) found in the last 7 days`,
        created: "New",
        open: "Open",
        pending: "Pending",
        picked: "Picked",
        completed: "Completed",
        rejected: "Rejected",
        cancelled: "Cancelled",
        undeliverable: "Manco",
        delivery: "Delivery",
        sender: "Sender",
        homeDelivery: "Home delivery",
        pickupCode: (code) => `Pick up (${code})`,
        nameClient: "Customer name",
        date: "Date",
        xAmountProducts: (amount) => `${amount} product(s)`,
        view: "View details",
        processOrder: "Process order",
        pickOrder: "Pick order",
        printOrder: "Print order",
        acceptAsUndeliverable: "Accept as manco",
        recirculate: "Recirculate",
        productsInXOrder: (orderNumber) => `Product(s) in order&nbsp;<strong>${orderNumber}</strong>`,
        paidPrice: "Paid price",
        articleNr: "Art no.",
        barcode: "Barcode",
        recirculateRequest: (identifier) => `Recirculate request ${identifier}`,
        withoutBranch: "Without branch",
        withBranch: "With branch",
        selectReason: "Select a reason",
        selectBranch: "Select a branch",
        openProducts: "Open product(s)",
        pickedProducts: "Picked product(s)",
        completedProducts: "Completed product(s)",
        undeliverableProducts: "Manco product(s)",
        amountTypeProductsFound: (amount, type) => `${amount} ${type} found`,
        acceptMancoProductConfirm: (barcode, identifier) =>
            `Are you sure you want to accept the product <b>${barcode}</b> in the request <b>${identifier}</b> as a defect?`,
        accept: "Accept",
        reject: "Reject",
        orderNr: "Order no.",
        recirculateProduct: (barcode) => `Recirculate product ${barcode}`,
        rejectProduct: "Reject product",
        reasonForRejection: (barcode) => `What is the reason you want to reject this product (${barcode})?`,
        reasonForRecirculation: "What is the reason you want to reject these products?",
        articleNumber: "Article number",
        name: "Name",
        sortBy: "Sort by",
        openPlural: "Open",
        pickedPlural: "Picked",
        completedPlural: "Completed",
        unkownPlural: "Unknown",
        acceptUndeliverableRequest: (identifier) => `Are you sure you want to accept the ${identifier} request as a manco?`,
        searchByOrderNr: "Search by ordernumber",
        search: "Search...",
        unknown: "Unknown",
        request: (identifier) => `Request "${identifier}"`,
        orderNumber: "Order number",
        creationDate: "creation date",
        searchByBarcode: "Search by barcode...",
        acceptAll: "Accept all",
        acceptRemaining: "Accept remaining",
        rejectAll: "Reject all",
        rejectRemaining: "Reject remaining",
        undoAll: "Undo all",
        confirmSelection: "Confirm selection",
        selected: "Selected",
        noSelectedArticles: "No selected articles",
        selectedArticle: (description, size) => `${description}, Size ${size}`,
        selectAReason: "Select a reason",
        packstation: "Packstation",
        selectAPackstation: "select a packstation",
        submitRequest: "Submit request",
        bundledRequestNotice:
            "<strong>Note:</strong> There are both accepted and rejected articles. A request may only be submitted as a bundled request with either only accepted or only rejected articles.<br/><br/>If one or more articles has/have been rejected, then the entire request must be rejected.",
        bundledRequestNoticePicked:
            "<strong>Note:</strong> Items have been both accepted and rejected. A request may only be submitted bundled because you are processing the order.<br/><br/>If one or more items have been rejected, you must reject the request completely, the entire request must then be re-picked.",
        xTypeProducts: (type) => `${type} producten`,
        xAmountOfxTypeProductsFound: (amount, type) => `${amount} ${type} ${amount === 1 ? "product" : "producten"} gevonden.`,
        structureCode: "Structure code",
        reset: "Reset",
        itemsFoundXFromX: (amount, total) => `${amount} from ${total} found`,
        pick: "Pick",
        noCrateSelected: "No crate is currently selected",
        noCrateSelectedDescription: "Scan the barcode of a crate before you start picking products.",
        scanCrate: "Scan crate",
        scanCrateDescription: "Scan the barcode of a crate",
        selectedCrateBarcode: (barcode) => `Crate with barcode <b>'${barcode}'</b> is selected`,
        acceptSelectedCrateBarcode: "I confirm that the above barcode is correct.",
        startPickingProducts: "You can now start picking products.",
        viewCrate: "View crate",
        completeCrate: "Complete crate",
        confirmCompletionCrate: "Are your sure you want to complete this crate?",
        crateDialogTitle: (barcode) => `Crate ${barcode}`,
        crateSuccessfullyCompleted: "Crate successfully completed.",
        noProductsInCrate: "There are no products in this crate.",
        oneProductInCrate: "There is one product in this crate.",
        xProductsInCrate: (amount) => `There are ${amount} products in this crate.`,
        continue: "Continue",
        close: "Close",
        delete: "Delete",
        resetFilters: "Reset all",
        recirculateAcceptedOrder: "Are you sure you want to recirculate the selected products?",
        selectProductsToRecirculate: "Select products to recirculate",
    },
    filters: {
        shippingAgents: "Shipping agents",
        productCount: "Amount of products",
        singleProduct: "Single product",
        multipleProducts: "Multiple products"
    },
    navigation: {
        open: "Open",
        picked: "Picked",
        completed: "Completed",
        undeliverable: "Undeliverable"
    },
    messages: {
        error: {
            somethingWentWrong: "Something went wrong.",
            somethingWentWrongRecirculation: "Something went wrong, so the product could not be recirculated.",
            somethingWentWrongAcceptMancoProduct: "Something went wrong, the undeliverable product could not be accepted.",
            somethingWentWrongAcceptProduct: "Something went wrong, the product could not be accepted.",
            somethingWentWrongRejectProduct: "Something went wrong, the product could not be rejected.",
            somethingWentWrongAcceptRequest: "Something went wrong, the pick request could not be accepted.",
            articleByBarcodeNotFound: (barcode) => `Article with barcode "${barcode}" could not be found.`
        },
        warning: {
            noAccessToUndeliverables: "Mancos in Order Circulatie",
            barcodeNotFound: (barcode) => `Barcode "${barcode}" not found.`,
            isNotCirculationBranch: "Please note: This branch does not participate in order circulation. If you continue with this request, please process the request by selecting the location in this application."
        },
        success: {
            undeliverableRecirculated: "The manco request is successfully recirculated!",
            undeliverableProductAccepted: "The manco is successfully accepted!",
            productWasAccepted: (name) => `<strong>${name}</strong> is successfully accepted.`,
            productWasAcceptedOnPage: (name, page) => `<strong>${name}</strong> has been successfully accepted on page <strong>${page}<strong>.`,
            productWasRejected: (name, reasonDescription) => `<strong>${name}</strong> is successfully rejected (${reasonDescription}).`,
            undeliverableRequestAccepted: "The manco request is successfully accepted.",
            articleWithBarcodeAccepted: (barcode) => `1 article with the barcode "${barcode}" has been accepted.`,
            requestSubmitted: "The request has been submitted successfully!",
            productWasRejectedPicked: (name) => `<strong>${name}</strong> has been successfully set back to the open products.`,
            productWasPicked: (name) => `<strong>${name}</strong> was picked successfully.`,
            productWasPickedOnPage: (name, page) => `<strong>${name}</strong> has been successfully picked on page <strong>${page}<strong>.`,
            productWasPlacedInCrate: (name) => `<strong>${name}</strong> is successfully placed in the crate.`,
            productWasPlacedInCrateOnPage: (name, page) => `<strong>${name}</strong> is successfully placed in the crate on page <strong>${page}<strong>.`,
            recirculateAcceptedOrder: "The selected products are successfully recirculated.",
        },
        info: {
            noBranchWithPermission:
                "You are not linked to a branch,<br/>but you do have rights to select a branch.<br/>To continue, choose a branch at the top right of the user menu<br/>or log in with an account which is linked to a branch.",
            noBranchWithoutPermission:
                "You are not linked to a branch.<br />This means you cannot place an order circulation request.<br /><br />To gain access, you must log in with an<br />account that is linked to a branch.",
            requestWillBePrinted: "The request will be printed.",
            packstationIsSelected: (packstationCode) => `<strong>${packstationCode}</strong><br/>is selected.`,
            productWillGetOpenStatus: `<strong>Note:</strong> The product will be given the status "open" and must be picked again in the "Open" tab.`,
            acceptedProductsWillGetPickedStatus: `<strong>Note:</strong> The accepted products in this request will be given the status "picked", process these further in the "Picked" tab.`,
            requestWillGetOpenStatus: `<strong>Note:</strong> The request will be given the status "open" and must be picked again in the "Open" tab.`,
        },
        validation: {
            selectReason: "You have to select a reason.",
            selectBranch: "You have to select a branch."
        },
        loading: {
            default: "Loading...",
            oneMomentPlease: "One moment please...",
            xTypeOrdersBeingLoaded: (type) => `${type} orders are loading...`,
            xTypeProductsBeingLoaded: (type) => `${type} producten worden geladen...`,
            requestIsSubmitting: "Request is submitting..."
        }
    }
};

export default enGb;
