const env = process.env;

const auth0Config = {
    domain: "divide.eu.auth0.com",
    scopes: "profile openid email chooseBranch admin get:settings get:endpoints get:order get:circulation put:circulation post:reprintshipment get:ordershipment put:ordershipment post:ordershipment get:packstation get:configuration post:circulation",
    clientId: env.REACT_APP_AUTH0_CLIENT_ID,
    audience: env.REACT_APP_AUTH0_AUDIENCE
};

export default auth0Config;
